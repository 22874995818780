import styled from '@emotion/styled'

export const Wrapper = styled.div`
	display: flex;
	position: absolute;
	top: 264px;
	right: 10px;
	width: 145px;
	height: 85px;
	cursor: pointer;

	.spin-tex-container {
		position: absolute;
    top: 12px;
    left: 32px;
    height: 63px;
    width: 96px;
    display: flex;
    justify-content: center;
		.spin-text {
			position: relative;
			font-size: 36px;
			font-weight: bold;
			font-family: "Carnevalee Freakshow" !important;
			color: #fff;
			letter-spacing: 3px;
			ext-shadow: 0 0 #000;
		}
	}

`;